import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import ModifyPassword from "./../../../nav/Admin/Profil/ModifyPassword";
import { Helmet } from "react-helmet";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <ModifyPassword user={user} />;
  } else {
    return <Error403 />;
  }
};

const ModifyPasswordPage = () => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    getUser(setUser);
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Changer de mot de passe</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici un formulaire pour modifier ton mot de passe."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,profil,mot de passe,modifier"
        />
        <link
          rel="canonical"
          href="https://wynit.com/admin/profil/modifier-mot-de-passe"
        />
      </Helmet>
      <HandleUserStates user={user} />
    </Layout>
  );
};

export default ModifyPasswordPage;
